
export const LeavingRule = {
  // 联系人
  contacts: [
    { required: true, message: "请输入联系人", trigger: "blur" },
    { max: 16, message: "联系人长度不能超过16位", trigger: "change" },
  ],
  // 联系方式
  phone: [
    { required: true, message: "请输入联系方式", trigger: "blur" },
    { max: 32, message: "联系方式长度不能超过32位", trigger: "change" },
  ],
  // 咨询与建议
  suggest: [
    { required: true, message: "请输入咨询与建议", trigger: "blur" },
    { max: 128, message: "咨询与建议长度不能超过128位", trigger: "change" },
  ],
};
