import axios from "@/config/http";
export const HomeAPI = {
  getBanner(params) {
    return axios.get(`/mall/v1/index/advertiseList`, { params });
  },
  hotGood(params) {
    return axios.get(`/mall/v1/index/hotGoodsList`, { params });
  },
  newGood(params) {
    return axios.get(`/mall/v1/index/newGoodsList`, { params });
  },
  scopeGood(params) {
    return axios.get(`/mall/v1/index/specialGoodsList`, { params });
  },
  // 留言
  async leavingMessage(params) {
    return await axios.post(`/mall/v1/index/message`, params);
  },
  listInventoryGoods(){
    return axios.get('/mall/v1/index/listInventoryGoods')
  }
};
