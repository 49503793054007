<template>
  <div class="home-container">
    <div class="loadiingBG" v-if="loading">
      <Loading style="height: 600px" />
    </div>
    <div class="home-content">
      <!-- 轮播 -->
      <div class="panel carousel-container">
        <a-carousel autoplay arrows>
          <div slot="prevArrow" class="custom-slick-arrow" style="left: 10px">
            <a-icon type="left-circle" />
          </div>
          <div slot="nextArrow" class="custom-slick-arrow" style="right: 10px">
            <a-icon type="right-circle" />
          </div>
          <div v-for="(item, index) in banner" :key="index">
            <img
              class="ponit"
              :src="item.coverImages"
              alt=""
              @click="JumpDetail(item.goodsId)"
            />
          </div>
        </a-carousel>
      </div>
      <!-- 新增实时数据 -->
      <div class="panel explosive-goods-container clearfix data-content">
        <div class="add-data">
          <div style="display: flex">
            <div><a-icon type="home" class="icon" /></div>
            <div>
              <div class="number-content">
                1025<span class="number-small"> 家 </span>
              </div>
              <div class="info-content">入驻工厂</div>
            </div>
          </div>
          <div style="display: flex">
            <div><a-icon type="team" class="icon" /></div>
            <div>
              <div class="number-content">
                206<span class="number-small"> 家 </span>
              </div>
              <div class="info-content">入驻贸易商</div>
            </div>
          </div>
          <div style="display: flex">
            <div><a-icon type="transaction" class="icon" /></div>
            <div>
              <div class="number-content">
                7064<span class="number-small"> 万 </span>
              </div>
              <div class="info-content">累计撮合交易额</div>
            </div>
          </div>
        </div>
        <div class="add-data">
          <div>
            <div class="number-content next">
              12000<span class="number-small next-small"> 个 </span>
            </div>
            <div class="info-content next-info">产品SKU数</div>
          </div>
          <div>
            <div class="number-content next">
              2057854<span class="number-small next-small"> 次 </span>
            </div>
            <div class="info-content next-info">产品浏览量</div>
          </div>
          <div>
            <div class="number-content next">
              124<span class="number-small next-small">次</span>
            </div>
            <div class="info-content next-info">月平均询盘数</div>
          </div>
        </div>
      </div>
      <!-- 爆品专供 -->
      <div class="panel explosive-goods-container clearfix">
        <div class="explosive-goods-img-container explode">
          <div class="explosive-goods-see-more" @click="routeList(2)">
            <span>查看更多</span>
            <a-icon type="right" />
          </div>
        </div>
        <div class="explosive-goods-list">
          <Swiper
            class="swiper-container"
            ref="mySwiper"
            :options="swiperOptions"
          >
            <SwiperSlide
              v-for="(item, index) in hotGood"
              :key="'hotgood' + index"
            >
              <div class="goods-list" @click="JumpDetail(item.goodsId)">
                <img
                  :src="item.coverImages"
                  alt=""
                  @mouseenter="handleMouseenter"
                  @mouseleave="handleMouseleave"
                />

                <div class="new-goods-name">
                  {{ item.goodsName }}
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          <div
            class="swiper-button swiper-button-prev"
            slot="button-prev"
            @click="next"
          >
            <a-icon type="left" class="icon_bg" />
          </div>
          <div
            class="swiper-button swiper-button-next"
            slot="button-next"
            @click="prev"
          >
            <a-icon type="right" class="icon_bg" />
          </div>
        </div>
      </div>
      <!-- 新品速递 -->
      <div class="panel title-panel new-goods-container">
        <div class="panel-title-container">
          <span class="title">新品速递</span>
          <div class="panel-see-more" @click="routeList(3)">
            <span>查看更多</span>
            <a-icon type="right-circle" />
          </div>
        </div>

        <div class="new-goods-card-container">
          <div
            class="new-goods-card"
            v-for="(item, index) in newgoods"
            :key="'newgoods' + index"
            :class="{ first: index === 0 }"
          >
            <img :src="item.coverImages" alt="" />
            <span class="bgname">{{ item.goodsName }}</span>
            <div class="bgmask" @click="JumpDetail(item.goodsId)">
              <span class="new-goods-name">{{ item.goodsName }}</span>
              <span class="new-goods-tips">{{ item.specialTag }}</span>
              <a-icon class="icons" type="right-circle" />
              <!-- <div class="see-detail-btn" @click="JumpDetail(item.goodsId)">
                了解详情
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <!-- 库存处理 -->
      <div
        class="panel private-gift-container"
        style="width: 100%; height: 375px"
      >
        <div style="display: flex; width: 100%">
          <div class="inventory-goods">
            <img src="../../../assets/img/inventory.png" alt="" />

            <div @click="routeList(5)" class="inventory-goods-see-more">
              <span>查看更多</span>
              <a-icon type="right" />
            </div>
          </div>
          <div class="private-gift-list">
            <InventoryCard
              v-for="(item, index) in inventoryGood"
              class="gift-detail"
              :key="'inventoryGood' + index"
              :item="item"
              @click.native="JumpDetail(item.goodsId, '库存处理')"
            />
          </div>
        </div>
      </div>
      <!-- 私域礼品 -->
      <div class="panel title-panel private-gift-container">
        <div class="panel-title-container">
          <span class="title">私域礼品 </span>
          <div class="panel-see-more" @click="routeList(4)">
            <span>查看更多</span>
            <a-icon type="right-circle" />
          </div>
        </div>
        <div class="private-gift-list">
          <GoodCard
            v-for="(item, index) in ScopeGood"
            class="gift-detail"
            :key="'ScopeGood' + index"
            :item="item"
            @click.native="JumpDetail(item.goodsId)"
          />
        </div>
      </div>
    </div>

    <!-- 留言按钮 -->
    <a-popover
      title="留言"
      :overlayStyle="{ width: '30%' }"
      placement="left"
      trigger="click"
    >
      <template slot="content">
        <a-form-model
          :label-col="labelCol"
          ref="ruleForm"
          :model="tradeMessageDTO"
          :rules="rules"
        >
          <a-form-model-item
            label="联系人"
            style="display: flex"
            prop="contacts"
            v-if="Token === ''"
          >
            <a-input
              placeholder="请输入联系人"
              v-model="tradeMessageDTO.contacts"
            ></a-input>
          </a-form-model-item>
          <a-form-model-item
            label="联系方式"
            style="display: flex"
            prop="phone"
            v-if="Token === ''"
          >
            <a-input
              placeholder="请输入您的联系方式"
              v-model="tradeMessageDTO.phone"
            ></a-input>
          </a-form-model-item>
          <a-form-model-item
            label="咨询与建议"
            style="display: flex"
            prop="suggest"
          >
            <a-textarea
              style="width: 18rem"
              :auto-size="{ minRows: 4 }"
              v-model="tradeMessageDTO.suggest"
            ></a-textarea>
          </a-form-model-item>

          <div>
            <a-button
              type="primary"
              style="margin-left: 10rem"
              @click="handleSubmitLeaving"
              >提交</a-button
            >
          </div>
        </a-form-model>
      </template>
      <!-- <a-button class="leaving-message" type="primary">  </a-button> -->
      <div class="leaving-message">
        <div><a-icon class="iconStyle" type="message" theme="twoTone" /></div>
        <div class="leaving">我要留言</div>
      </div>
    </a-popover>
  </div>
</template>
<script>
import GoodCard from "@/components/GoodCard"
import InventoryCard from "@/components/InventoryCard"
import { Swiper, SwiperSlide } from "vue-awesome-swiper"
import { createNamespacedHelpers } from "vuex"
import { HomeAPI } from "@/api/Home.js"
const { mapState, mapMutations } = createNamespacedHelpers("Public")
// import 'swiper/css/swiper.css';
import Loading from "@/components/loading"
import Routerpush from "@/mixins/Routerpush"
import { MallAPI } from "@/api/mall.js"
import { tips } from "@/utils/tips.js"
import { LeavingRule } from "@/rules/leavingMessage.js"
export default {
  mixins: [Routerpush],
  data () {
    return {
      labelCol: { span: 6 },
      banner: [
        // { coverImages: require('@/assets/img/home-ad-1.png') },
        // { coverImages: require('@/assets/img/home-ad-1.png') },
      ],
      newgoods: [
        {
          img: require("@/assets/img/goods.png"),
          name: "电子除味器",
        },
        {
          img: require("@/assets/img/home-ad-1.png"),
          name: "电子除味器",
        },
        {
          img: require("@/assets/img/home-ad-1.png"),
          name: "电子除味器",
        },
        {
          img: require("@/assets/img/home-ad-1.png"),
          name: "电子除味器",
        },
        {
          img: require("@/assets/img/home-ad-1.png"),
          name: "电子除味器",
        },
        {
          img: require("@/assets/img/home-ad-1.png"),
          name: "电子除味器",
        },
      ],
      hotGood: [],
      ScopeGood: [
        {
          img: require("@/assets/img/goods.png"),
          name: "电子除味器",
          price: "123",
          start_price: "567",
          tag: ["bbb", "aaa", "vvv"],
          views: "7689",
        },
        {
          img: require("@/assets/img/goods.png"),
          name: "电子除味器",
          price: "123",
          start_price: "567",
          tag: ["bbb", "aaa", "vvv"],
          views: "7689",
        },
        {
          img: require("@/assets/img/goods.png"),
          name: "电子除味器",
          price: "123",
          start_price: "567",
          tag: ["bbb", "aaa", "vvv"],
          views: "7689",
        },
        {
          img: require("@/assets/img/goods.png"),
          name: "电子除味器",
          price: "123",
          start_price: "567",
          tag: ["bbb", "aaa", "vvv"],
          views: "7689",
        },
        {
          img: require("@/assets/img/goods.png"),
          name: "电子除味器",
          price: "123",
          start_price: "567",
          tag: ["bbb", "aaa", "vvv"],
          views: "7689",
        },
      ],
      inventoryGood: [
        {
          img: require("@/assets/img/goods.png"),
          name: "电子除味器",
          price: "123",
          inventoryNumber: "567",
          inventoryTags: ["bbb", "aaa", "vvv"],
          readNumber: "7689",
        },
        {
          img: require("@/assets/img/goods.png"),
          name: "电子除味器",
          price: "123",
          inventoryNumber: "567",
          inventoryTags: ["bbb", "aaa", "vvv"],
          readNumber: "7689",
        },
        {
          img: require("@/assets/img/goods.png"),
          name: "电子除味器",
          price: "123",
          inventoryNumber: "567",
          inventoryTags: ["bbb", "aaa", "vvv"],
          readNumber: "7689",
        },
        {
          img: require("@/assets/img/goods.png"),
          name: "电子除味器",
          price: "123",
          inventoryNumber: "567",
          inventoryTags: ["bbb", "aaa", "vvv"],
          readNumber: "7689",
        },
      ],
      swiperOptions: {
        // speed: 200,
        // slidesPerView: 4, //显示个数
        speed: 400,
        loop: true,
        slidesPerView: "auto",
        loopedSlides: 5,

        spaceBetween: 20,
        autoplay: {
          delay: 2000,
          // delay: 1000,
        },
        navigation: {
          nextEl: ".swiper-button-prev",
          prevEl: ".swiper-button-next",
        },
      },
      login: {
        username: "",
        password: "",
      },
      loading: true,
      // 留言
      tradeMessageDTO: {
        contacts: null, // 联系人
        phone: null, // 联系方式
        suggest: null, // 咨询与意见
      },
      rules: LeavingRule,
      timer: null,
    }
  },

  components: {
    GoodCard,
    InventoryCard,
    Swiper,
    SwiperSlide,
    Loading,
  },

  computed: {
    ...mapState(["Token", "firstStatus", "Num"]),
    swiper () {
      return this.$refs.mySwiper.$swiper
    },
  },

  async mounted () {
    await this.getInventoryGood()
    await this.getBanner()
    await this.getHotGood()
    await this.getNewGood()
    console.log('this.newgoods', this.newgoods)
    await this.getScopeGood()
    this.autoplay()
    this.loading = false
  },
  destroyed () {
    clearInterval(this.timer)
    this.timer = null
  },
  methods: {
    ...mapMutations(["setStatus", "setToken"]),
    prev () {
      this.swiper?.slideNext()
    },
    next () {
      this.swiper?.slidePrev()
    },
    autoplay () {
      this.timer = setInterval(() => {
        this.prev()
      }, 2000)
    },
    handleMouseenter () {
      clearInterval(this.timer)
    },
    handleMouseleave () {
      this.timer = setInterval(() => {
        this.prev()
      }, 2000)
      this.swiper.slidePrev()
      // this.swiper.slideNext();
    },


    //点击提示
    tips () {
      return new Promise((resolve) => {
        if (this.Token === "") {
          let that = this
          this.$confirm({
            title: "提示",
            content: "为了便于您了解选品详情，请登录后进行查看。",
            okText: "去登录",
            cancelText: "取 消",
            onOk () {
              that.loginRoute()
            },
            onCancel () {
              // that.setStatus(false);
            },
          })
        } else {
          resolve()
        }
      })
    },

    //查看详情
    JumpDetail (id, type) {
      if (type === '库存处理') {
        if (id === null) {
          this.$router.push("/detailIndex")
        } else {
          this.tips().then(() => {
            this.SeeDetail(id,'5')
          })
        }
      }else{
        if (id === null) {
        this.$router.push("/detailIndex")
      } else {
        this.tips().then(() => {
          this.SeeDetail(id)
        })
      }
      }
      
    },

    //获取banner
    getBanner () {
      return new Promise((resolve) => {
        HomeAPI.getBanner().then((res) => {
          resolve()
          if (res.success) {
            this.banner = res.data
            let detail = {
              coverImages: require("../../../assets/img/propaganda/bannertt.jpeg"),
              goodsId: null,
            }
            this.banner.unshift(detail)

          }
        })
      })
    },

    //获取爆品
    getHotGood () {
      return new Promise((resolve) => {
        HomeAPI.hotGood().then((res) => {
          resolve()
          if (res.success) {
            console.log(res.data)
            this.hotGood = res.data
          }
        })
      })
    },

    //获取新品
    getNewGood () {
      return new Promise((resolve) => {
        HomeAPI.newGood().then((res) => {
          resolve()
          if (res.success) {
            this.newgoods = res.data
          }
        })
      })
    },

    //获取私域栏目
    getScopeGood () {
      return new Promise((resolve) => {
        HomeAPI.scopeGood().then((res) => {
          resolve()
          if (res.success) {
            console.log(res.data)
            if (res.data.length > 5) {
              res.data = res.data.slice(0, 5)
            }
            this.ScopeGood = res.data.map((i) => {
              i.title = "私域礼品"
              i.specialTag =
                i.specialTag === "" || i.specialTag === null
                  ? []
                  : i.specialTag.split("/")
              return i
            })
          }
        })
      })
    },
    getInventoryGood () {
      return new Promise((resolve) => {
        HomeAPI.listInventoryGoods().then((res) => {
          resolve()
          if (res.success) {
            if (res.data.length > 4) {
              res.data = res.data.slice(0, 4)
            }
            this.inventoryGood = res.data.map((i) => {
              i.title = "库存处理"
              i.inventoryTags =
                i.inventoryTags === "" || i.inventoryTags === null
                  ? []
                  : i.inventoryTags.split("/")
              return i
            })
          }
        })
      })
    },
    checkStatus (id,type) {
      MallAPI.checkStatus().then((res) => {
        console.log(res)
        console.log(id, "打来新页签")
        let url = this.$router.resolve({
          path: `detail?goodsId=${id}`,
          query: {
            name: type
          }
        })
        window.open(url.href) //注意，需要加href
      })
    },

    //查看详情
    SeeDetail (id,type) {
      this.checkStatus(id,type)
    },

    // 跳转详情
    routeList (type) {
      this.$router.push("/list?type=" + type)
    },

    // 提交留言
    handleSubmitLeaving () {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          HomeAPI.leavingMessage(this.tradeMessageDTO).then((res) => {
            console.log(res.data)
            tips(res, "留言")
            if (res.success) {
              console.log("dafdsfasdf")
              for (let item in this.tradeMessageDTO) {
                this.tradeMessageDTO[item] = null
              }
            }
          })
        }
      })
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../css/Home.scss";
.gift-detail {
  margin-right: 10px;
}
.loadiingBG {
  background: rgba(255, 255, 255, 0.3);
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.home-container {
  position: relative;
  .leaving-message {
    padding: 4px;
    background: #fff;
    position: fixed;
    right: 3%;
    top: 55%;
  }
  .leaving {
    color: rgb(0, 137, 250);
    font-size: 12px;
  }
}
.iconStyle {
  font-size: 2.5rem;
}
.ant-input {
  width: 12rem;
}
.data-content {
  height: 295px;
  .number-content {
    color: #f94639;
    font-size: 50px;
    .number-small {
      color: #f94639;
      font-size: 17px;
    }
  }
  .next {
    font-size: 40px;
    .next-small {
      font-size: 14px;
    }
  }
  .info-content {
    color: black;
    font-size: 23px;
  }
  .next-info {
    font-size: 20px;
  }
}
.add-data {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 30px;
  margin-top: 20px;
}
.icon {
  margin-right: 10px;
  font-size: 80px;
}
.inventory-goods {
  width: 202px;
  height: 375px;
  margin-right: 20px;
  position: relative;
  img {
    width: 202px;
    height: 375px;
  }
}
.inventory-goods-see-more {
  position: absolute;
  bottom: 90px;
  left: 70px;
  color: #ffffff;
  font-size: 12px;
  user-select: none;
  cursor: pointer;
}
</style>
